import { Chip } from '@mui/material'

import { Routes } from 'constants/index'
import { addTestId } from 'helpers/tests'
import { useTranslation } from 'react-i18next'
import { Session } from 'types'

import { ResponsesResourceCounts, useGetApiV1UsersPendingCount } from 'api'
import { AdminResourceNav } from '.'
import { StyledNavLink, StyledNavMenuItem } from './styles'

type Props = {
  navigate: (path: string) => void
  currentPath: string
  session: Session
  resourceNav: AdminResourceNav[]
  resourceCounts: ResponsesResourceCounts | null
}

const AdminNav = ({
  session,
  navigate,
  currentPath,
  resourceNav,
  resourceCounts,
}: Props): JSX.Element | null => {
  const { t } = useTranslation()

  const { data: pendingCount } = useGetApiV1UsersPendingCount({})

  if (!session) {
    return null
  }

  return (
    <>
      <StyledNavLink
        dest={Routes.Assets}
        navigate={navigate}
        label={t('nav.admin.assets')}
        activeTab={currentPath}
        {...addTestId('nav-link-assets')}
      >
        {resourceNav.map(nav => (
          <StyledNavMenuItem
            className="with-chip"
            dest={nav.path}
            key={nav.path}
            {...addTestId(`nav-link-assets-${nav.key}`)}
          >
            {t(nav.label)}
            {resourceCounts && resourceCounts[nav.count] !== undefined && (
              <Chip
                size="small"
                label={resourceCounts[nav.count]!.toLocaleString()}
              />
            )}
          </StyledNavMenuItem>
        ))}
      </StyledNavLink>

      <StyledNavLink
        dest="/admin/users"
        count={(pendingCount?.count || 0).toLocaleString()}
        navigate={navigate}
        label={t('nav.admin.users')}
        activeTab={currentPath}
        {...addTestId('nav-link-users')}
      >
        <StyledNavMenuItem
          dest={Routes.AdminUsers}
          {...addTestId('nav-link-users-all')}
        >
          {t('nav.admin.all_users')}
        </StyledNavMenuItem>

        {session.isDirector && (
          <StyledNavMenuItem
            dest="/admin/pending_users"
            className="with-chip"
            {...addTestId('nav-link-users-pending')}
          >
            {t('nav.admin.pending_users')}
            {pendingCount && (
              <Chip size="small" label={pendingCount?.count.toLocaleString()} />
            )}
          </StyledNavMenuItem>
        )}
      </StyledNavLink>

      <StyledNavLink
        dest="/earnings/charges"
        navigate={navigate}
        label={t('nav.admin.earnings.title')}
        activeTab={currentPath}
        {...addTestId('nav-link-earnings')}
      >
        <StyledNavMenuItem
          dest="/earnings/charges"
          {...addTestId('nav-link-earnings-charges')}
        >
          {t('nav.admin.earnings.charges')}
        </StyledNavMenuItem>

        <StyledNavMenuItem
          dest="/earnings/invoices"
          {...addTestId('nav-link-earnings-invoices')}
        >
          {t('nav.admin.earnings.invoices')}
        </StyledNavMenuItem>

        <StyledNavMenuItem
          dest="/earnings/payouts"
          {...addTestId('nav-link-earnings-payouts')}
        >
          {t('nav.admin.earnings.payouts')}
        </StyledNavMenuItem>

        <StyledNavMenuItem
          dest="/earnings/accounts"
          {...addTestId('nav-link-earnings-accounts')}
        >
          {t('nav.admin.earnings.accounts')}
        </StyledNavMenuItem>
      </StyledNavLink>

      {session.isSuperAdmin && (
        <StyledNavLink
          dest={Routes.Trestle}
          navigate={navigate}
          label={t('nav.admin.admin')}
          activeTab={currentPath}
          {...addTestId('nav-link-trestle')}
        >
          <StyledNavMenuItem dest={Routes.Trestle}>
            {t('nav.admin.trestle')}
          </StyledNavMenuItem>
          <StyledNavMenuItem dest={Routes.Flipper}>
            {t('nav.admin.flipper')}
          </StyledNavMenuItem>
          <StyledNavMenuItem dest={Routes.Sidekiq}>
            {t('nav.admin.sidekiq')}
          </StyledNavMenuItem>
        </StyledNavLink>
      )}
    </>
  )
}

export default AdminNav
