import { Button } from 'eezy-components'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import AstronautAnimation from './astronaut'
import * as S from './styled'

const NotFound = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <>
      <S.Container>
        <AstronautAnimation />
        <S.Title variant="h5">{t('page_not_found.title')}</S.Title>
        <Button color="primary" onClick={() => navigate('/')}>
          {t('page_not_found.go_back_home')}
        </Button>
      </S.Container>
    </>
  )
}

export default NotFound
